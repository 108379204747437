import { of } from "rxjs";
import { APP_INITIALIZER, inject } from "@angular/core";
import { FeatureFlagService } from "../services/feature-flag.service";
import { catchError } from "rxjs/operators";

function initializeFeatureFlag(): () => Promise<any> {
  const featureFlagService = inject(FeatureFlagService);

  return () => {
    return featureFlagService
      .fetchFeatureFlags()
      .pipe(
        catchError((error) => {
          console.error("Error fetching feature flags:", error);
          return of(null);
        }),
      )
      .toPromise();
  };
}

export const provideFeatureFlag = () => ({
  provide: APP_INITIALIZER,
  useFactory: initializeFeatureFlag,
  deps: [],
  multi: true,
});
