<div class="customizerNav">
  <ng-scrollbar class="position-relative" style="height: calc(100vh - 50px)">
    <div class="p-x-16 p-y-20">
      <h3 class="mat-subtitle-1 f-s-21 f-w-600">Settings</h3>
    </div>
    <mat-divider></mat-divider>
    <div class="p-24">
      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16">Theme Option</h6>
      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.theme" (change)="setDark()"
        class="customizer-button-group gap-16">
        <mat-button-toggle [value]="'light'">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="sun-high" class="d-flex m-r-8 fill-icon"></i-tabler>
            Light
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="'dark'">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="moon" class="d-flex m-r-8 fill-icon"></i-tabler>
            Dark
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div>
        <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">
          Theme Colors
        </h6>
        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.activeTheme" (change)="setColor()"
          class="customizer-button-group theme-colors two-row">
          <mat-button-toggle [value]="'orange_theme'">
            <span class="theme-circle orange_theme" matTooltip="orange_theme" matTooltipClass="text-uppercase"
              matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'blue_theme'">
            <span class="theme-circle blue_theme" matTooltip="blue_theme" matTooltipClass="text-uppercase"
              matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'aqua_theme'">
            <span class="theme-circle aqua_theme" matTooltip="aqua_theme" matTooltipClass="text-uppercase"
              matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'purple_theme'">
            <span class="theme-circle purple_theme" matTooltip="purple_theme" matTooltipClass="text-uppercase"
              matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>

          <mat-button-toggle [value]="'green_theme'">
            <span class="theme-circle green_theme" matTooltip="green_theme" matTooltipClass="text-uppercase"
              matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
          <mat-button-toggle [value]="'pink_theme'">
            <span class="theme-circle pink_theme" matTooltip="pink_theme" matTooltipClass="text-uppercase"
              matTooltipPosition="above">
              <i-tabler name="check" class="icon-16 theme-icon"></i-tabler>
            </span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24">
        Theme Direction
      </h6>

      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.dir" (change)="setDir()"
        class="customizer-button-group gap-16">
        <mat-button-toggle [value]="'ltr'">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="text-direction-ltr" class="d-flex m-r-8 fill-icon"></i-tabler>
            LTR
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="'rtl'">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="text-direction-rtl" class="d-flex m-r-8 fill-icon"></i-tabler>
            RTL
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      @if(!options.horizontal) {
      <div>
        <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">
          Sidebar type
        </h6>

        <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.sidenavCollapsed"
          (change)="setSidebar()" class="customizer-button-group gap-16">
          <mat-button-toggle [value]="false">
            <div class="d-flex align-items-center f-w-500">
              <i-tabler name="layout-sidebar-right" class="d-flex m-r-8 fill-icon"></i-tabler>
              Full
            </div>
          </mat-button-toggle>
          <mat-button-toggle [value]="true">
            <div class="d-flex align-items-center f-w-500">
              <i-tabler name="layout-sidebar" class="d-flex m-r-8 fill-icon"></i-tabler>
              Minisidebar
            </div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      }

      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">
        Layout type
      </h6>

      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.horizontal"
        class="customizer-button-group gap-16">
        <mat-button-toggle [value]="false">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="layout-distribute-vertical" class="d-flex m-r-8 fill-icon"></i-tabler>
            Vertical
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="true">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="layout-distribute-horizontal" class="d-flex m-r-8 fill-icon"></i-tabler>
            Horizontal
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">
        Card with
      </h6>

      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.cardBorder"
        class="customizer-button-group gap-16">
        <mat-button-toggle [value]="false">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="shadow" class="d-flex m-r-8 fill-icon"></i-tabler>
            Shadow
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="true">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="shadow-off" class="d-flex m-r-8 fill-icon"></i-tabler>
            Border
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <h6 class="f-s-16 f-w-600 mat-subtitle-1 f-s-16 m-b-16 m-t-24 p-t-16">
        Container Option
      </h6>

      <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="options.boxed"
        class="customizer-button-group gap-16">
        <mat-button-toggle [value]="false">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="layout-navbar" class="d-flex m-r-8 fill-icon"></i-tabler>
            Full
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="true">
          <div class="d-flex align-items-center f-w-500">
            <i-tabler name="layout-sidebar" class="d-flex m-r-8 fill-icon"></i-tabler>
            Boxed
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-scrollbar>
</div>