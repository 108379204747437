import {inject, Injectable, signal} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {FeatureFlag, FeatureFlagKeys} from "../types/feature-flag.type";
import {environment} from "../environments/environment";

const API_URL = environment.sURL

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  http = inject(HttpClient);
  featureFlags = signal<Record<string, boolean>>({});

  constructor() {
  }

  fetchFeatureFlags(): Observable<{
    status: string
    result: FeatureFlag[]
  }> {
    return this.http
      .get<{
        status: string
        result: FeatureFlag[]
      }>(
        `${API_URL}feature-flag`
      )
      .pipe(
        tap((response) => {
          const features = response?.result;
          if (response?.status === "ok" && features?.length > 0) {
            let featureFlags: Record<string, boolean> = {};
            features?.forEach((item: FeatureFlag) => {
              featureFlags[item.NAME] = item?.IS_ENABLED;
            });
            this.featureFlags.set(featureFlags);
          }
        }),
      );
  }

  getFeature(feature: FeatureFlagKeys): boolean {
    return this.featureFlags()[feature] ?? false;
  }
}
