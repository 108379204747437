import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TablerIconsModule } from 'angular-tabler-icons';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NavService } from '../../../../../../../../shared/src/lib/services/nav.service';
import { NavItem } from '../../../vertical/sidebar/nav-item/nav-item';

@Component({
  selector: 'app-horizontal-nav-item',
  standalone: true,
  imports: [TablerIconsModule, CommonModule, MatIconModule,RouterModule],
  templateUrl: './nav-item.component.html',
})
export class AppHorizontalNavItemComponent implements OnInit {
  @Input() depth: any;
  @Input() item: any;

  constructor(
    public navService: NavService,
    public router: Router,
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {}
  onItemSelected(item: any) {
    
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
    }
  }

  openInNewTab(event: MouseEvent, item: any) {
    // Prevent the default context menu from appearing
    event.preventDefault();

    // Open the URL in a new tab
    const url = this.router.serializeUrl(this.router.createUrlTree([item.route]));
    window.open(url, '_blank');
  }
}
