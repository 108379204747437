import { environment } from '../../../../../../../shared/src/lib/environments/environment';
import { NavItem } from './nav-item/nav-item';
export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Starter',
    iconName: 'home',
    route: '/starter',
  },
  {
    displayName: 'Users',
    iconName: 'home',
    route: 'home/users',
  },
  // {
  //   displayName: 'Reports',
  //   iconName: 'home',
  //   route: 'home/report',
  // },
  {
    displayName: 'Betting P/L',
    iconName: 'home',
    route: '/starter',
  },
  {
    displayName: 'Account Statement',
    iconName: 'home',
    route: '/reports',
  },

  // {
  //   displayName: 'Bet Status',
  //   iconName: 'home',
  //   route: 'home/bet',
  // },
  {
    displayName: 'Bet Info',
    iconName: 'home',
    route: 'home/bet-info',
  },
  {
    displayName: 'White Labels',
    iconName: 'home',
    route: 'home/white-label',
  },
  // {
  //   displayName: 'Notificaions',
  //   iconName: 'home',
  //   route: '/reports',
  // },
  // {
  //   displayName: 'Wallets',
  //   iconName: 'home',
  //   route: 'home/wallet',
  // },
  // {
  //   navCap: 'Funds',
  // },

  // {
  //   displayName: 'Deposit',
  //   iconName: 'home',
  //   route: 'home/wallet-transfer',
  // },

  // {
  //   displayName: 'Withdraw',
  //   iconName: 'home',
  //   route: '/wl',
  // },
  {
    navCap: 'Configuration',
  },
  {
    displayName: 'Betfair Accounts',
    iconName: 'home',
    route: 'configuration/betfair-accounts',
  },
  // {
  //   displayName: 'Live Score',
  //   iconName: 'home',
  //   route: '/wl',
  // },
  // {
  //   displayName: 'Live Stream',
  //   iconName: 'home',
  //   route: '/wl',
  // },
  {
    displayName: 'Commission',
    iconName: 'home',
    route: 'home/commission',
  },
  {
    displayName: 'Risk Taking',
    iconName: 'home',
    route: 'home/risk',
  },
  {
    displayName: 'Exchange Rate',
    iconName: 'home',
    route: 'configuration/exchange-rate',
  },
  {
    navCap: 'Settings',
  },

  {
    displayName: 'Supported Countries',
    iconName: 'home',
    route: 'settings/supported-countries',
  },
  {
    displayName: 'Supported Sports',
    iconName: 'home',
    route: 'settings/supported-sports',
  },
  {
    displayName: 'Roles',
    iconName: 'home',
    route: 'settings/roles',
  },
  // {
  //   displayName: 'Permissions',
  //   iconName: 'home',
  //   route: '/sample',
  // },
];
