@if(mobileQuery.matches) {
<div class="horizontal-sidebar p-y-8 b-b-1">
  <div class="">
    <div>
      <div class="horizontal-navbar hstack align-items-center"  style="flex-wrap: wrap">
        @for(item of navItems; track item) {
        <app-horizontal-nav-item [item]="item" class="parentBox {{ item.ddType }}" [ngClass]="{
                pactive: item.route == parentActive ? 'pactive' : ''
              }">
        </app-horizontal-nav-item>
        }

      </div>
    </div>
  </div>
</div>
}
