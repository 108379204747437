@if(item.navCap) {
<div mat-subheader class="nav-caption">
  {{ item.navCap }}
</div>
}

@if(!item.navCap && !item.external) {
<mat-list-item  (click)="onItemSelected(item)" [ngClass]="{
      'mat-toolbar mat-accent activeMenu': item.route
        ? router.isActive(item.route, true)
        : false,
      expanded: expanded,
    }" class="menu-list-item rounded-pill">
  <i-tabler name="{{ item.iconName }}" class="routeIcon" matListItemIcon></i-tabler>
  <span class="hide-menu">{{ item.displayName | translate }}</span>
  @if(item.children && item.children.length) {
  <span class="arrow-icon" fxFlex>
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
  }

</mat-list-item>

}


<!-- children -->
@if(expanded) {
  @for(child of item.children; track child) {
  <app-nav-item [item]="child" (click)="onSubItemSelected(child)" [depth]="depth + 1">
  </app-nav-item>
  }
}