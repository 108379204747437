import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
// import { sEndPoint } from '.env.properties';
//set up environment variable
// export const sURL = 'https://scratch-api.aggregator.club/' + 'v1/api/';
// export const sURL = 'http://localhost:8080/' + 'v1/api/';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';

export interface ApiResponse {
  result: any[];
  status: any;
}

@Injectable({providedIn: 'root'})
export class ApiService {
  $tokenRefreshed = new Subject<boolean>();
  $refreshToken = new Subject<boolean>();
  oRoles = null;
  oPermissions = null;

  constructor(private http: HttpClient, private router: Router) {
  }

  clearUserToken() {
    localStorage.removeItem('app');
    localStorage.removeItem('lastActivityTime');
    this.oPermissions = null;
  }

  getPermissions() {
    return this.oPermissions;
  }

  getRoles() {
    return this.oRoles;
  }

  saveUserToken(data: any) {
    localStorage.setItem('app', JSON.stringify(data));
    let oData = JSON.parse(atob(data.access_token.split('.')[1]));
    let oUserFields = oData.user_fields;
    this.oRoles = oUserFields.ROLES;
    this.oPermissions = oUserFields.PERMISSIONS;
  }

  getUserToken() {
    const oSessionData = localStorage.getItem('app');
    if (oSessionData != null) {
      return JSON.parse(oSessionData);
    }
    return null;
  }

  getUserData() {
    let oUserData = this.getUserToken();
    let oData = JSON.parse(atob(oUserData.access_token.split('.')[1]));
    let oUserFields = oData.user_fields;
    let id = oUserFields.ID;
    let name = oUserFields.NAME;
    let email = oUserFields.EMAIL;
    let roles = oUserFields.ROLES;
    let permissions = oUserFields.PERMISSIONS;
    let parentId = oUserFields.PARENT_ID

    return {ID: id, NAME: name, EMAIL: email, PARERNT_ID: parentId, ROLE: roles, PERMISSIONS: permissions}
  }

  performTokenRefresh() {
    let url = environment.bURL + '/v1/auth/renew';
    const refreshToken = JSON.parse(localStorage.getItem("app") ?? "{}")?.refresh_token

    if (refreshToken) {
      this.send2Server(url, true, null, {"refresh-token": refreshToken}).subscribe({
        next: (response: any) => {
          console.log(response);
          if (response) {
            let oUserData = {
              access_token: response.access_token,
              refresh_token: response.refresh_token,
              user: response.user_fields,
            };
            this.saveUserToken(oUserData);
            window.location.reload();
          }
        },
        error: () => {
          this.logout()
        }
      });
    } else {
      this.logout()
    }
  }

  send2Server(
    url: string,
    bPost: boolean = false,
    oItem: any = null,
    extraHeaders?: Record<string, string | number>
  ): Observable<any> {
    if (bPost) {
      return this.http.post<ApiResponse>(url, oItem, this.getHeaders(extraHeaders));
    }
    return this.http.get<ApiResponse>(url, this.getHeaders());
  }

  getURL() {
    return environment.sURL;
  }
  getRiskCalculatorURL() {
    return environment.riskCalculatorURL;
  }

  getHeaders(extraHeaders?: Record<string, string | number>): any {
    var oHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      ...extraHeaders
    });
    return {headers: oHeaders};
  }

  async performLogin(uname: string, pwd: string): Promise<boolean> {
    let code = localStorage.getItem('countryCode');
    if (uname === 'admin' && pwd === 'admin123') {
      let oUserData = {
        access_token: 'DEV_TOKEN',
        name: uname,
      };
      this.saveUserToken(oUserData);
      return true;
    }
    try {
      const data: any = await this.send2ServerRelative('auth/admin_login', true, {
        USERNAME: uname,
        PASSWORD: pwd,
        COUNTRY_CODE: code
      }).toPromise();
      let oUserData = {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        name: data.user_fields.NAME,
      };
      this.saveUserToken(oUserData);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  logout() {
    this.clearUserToken();
    this.router.navigate(['authentication/login']);
  }

  send2ServerRelative(
    sPartialURL: string,
    bPost: boolean = false,
    oItem: any = null,
  ): Observable<any> {
    if (bPost) {
      return this.http.post<ApiResponse>(
        this.getURL() + sPartialURL,
        oItem,
        this.getHeaders(),
      );
    }
    return this.http.get<ApiResponse>(
      this.getURL() + sPartialURL,
      this.getHeaders(),
    );
  }
}
