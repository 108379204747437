import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  private timeout: any;
  private readonly sessionTimeout: number = environment.sessionTimeout;
  private sessionKey = 'lastActivityTime';

  constructor(private router: Router, private apiService: ApiService) { }

  setupSessionTimeout() {
    this.restoreSession();
    this.resetTimeout();
    this.addActivityListeners();
    this.handleTabEvents();
  }
  private addActivityListeners(): void {
    ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach(event => {
      window.addEventListener(event, () => this.resetTimeout());
    });
  }

  private saveSessionActivity(): void {
    if (this.sessionTimeout > 0 && this.apiService.getUserToken()) {
      const currentTime = Date.now();
      localStorage.setItem(this.sessionKey, currentTime.toString());
    }
  }

  private resetTimeout(): void {
    clearTimeout(this.timeout);
    this.saveSessionActivity();
    if (this.sessionTimeout > 0 && this.apiService.getUserToken() ) {
      this.timeout = setTimeout(() => this.logout(), this.sessionTimeout );
    }
  }

  private restoreSession(): void {
    const lastActivity = localStorage.getItem(this.sessionKey);
    if (lastActivity) {
      const elapsedTime = Date.now() - parseInt(lastActivity, 10);
      if (elapsedTime > this.sessionTimeout) {
        this.logout();
      }
    }
  }

  private handleTabEvents(): void {  // Track when the tab becomes visible again
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.restoreSession();
        this.resetTimeout();
      }
    }); // Track when the user closes or refreshes the tab
    window.addEventListener('beforeunload', () => {
      this.saveSessionActivity();
    });
  }
  private logout() {
    this.apiService.clearUserToken();
    this.router.navigate(['authentication/login']);
  }
}