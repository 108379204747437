import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {ApiService} from './api.service';
import {inject} from '@angular/core';
import {throwError} from 'rxjs';

export const customInterceptor: HttpInterceptorFn = (req, next) => {
  const oApiService = inject(ApiService);
  let oUserData = oApiService.getUserToken();
  if (oUserData == null) {
    return next(req);
  }
  const cloneReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${oUserData.access_token}`,
    },
  });
  return next(cloneReq).pipe(
    catchError((err: any) => {
      if (req.headers.has('Refresh-Token')) {
        oApiService.logout()
      }else if (err instanceof HttpErrorResponse && err.status === 401 && !req.url.includes('logout')) {
        const bRefresh = confirm('Session Expired. Continue?');
        if (bRefresh) {
          oApiService.performTokenRefresh();
        } else {
          oApiService.logout();
        }
      }

      return throwError(() => err);
    })
  );
};
